import { getHostApis } from '~/utils/host-browser-apis/getHost'

interface GetSearchUrlOption {
  fallbackUrl: string
  queryPlaceholder: string
}

interface GetSearchUrl {
  url: string
  isMonetized?: boolean
}

const defaultGetSearchOptions: Partial<GetSearchUrlOption> = {
  queryPlaceholder: '{searchTerms}',
}

export async function getSearchUrl(
  searchTerm: string,
  options: Partial<GetSearchUrlOption>
): Promise<GetSearchUrl> {
  const { fallbackUrl, queryPlaceholder } = { ...defaultGetSearchOptions, ...options } as GetSearchUrlOption

  try {
    const generateSearchUrl = (searchUrl: string) =>
      searchUrl.replace(queryPlaceholder, encodeURIComponent(searchTerm))

    const { shift, onelaunch } = getHostApis()

    const newShiftApi = shift?.search?.getDefault
    if (newShiftApi) {
      const searchPreferences = await newShiftApi()
      const isMonetized = searchPreferences.monetized
      const url = generateSearchUrl(searchPreferences.url)
      return { url, isMonetized }
    }

    // TODO: remove after the new api is the standard for most users
    const shiftLegacyApi = shift?.getServerConfig
    if (shiftLegacyApi) {
      const updateServiceSettings = await shiftLegacyApi()
      return { url: generateSearchUrl(updateServiceSettings.search_url) }
    }

    const onelaunchApi = onelaunch?.getMergedData
    if (onelaunchApi) {
      const updateServiceSettings = await onelaunchApi()
      return { url: generateSearchUrl(updateServiceSettings.search_url) }
    }

    return { url: generateSearchUrl(fallbackUrl) }
  } catch (e) {
    console.error(e)
    return { url: fallbackUrl }
  }
}
