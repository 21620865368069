import { getHostApis } from './getHost'

export async function getSearchEngineConfig(): Promise<Shift.ShiftSearchEngineConfig | undefined> {
  const { shift } = getHostApis()
  const shiftSearchEngineConfig = shift?.search?.getDefault
  if (shiftSearchEngineConfig) {
    return shiftSearchEngineConfig()
  }

  return undefined
}
